import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Link, graphql } from "gatsby"

import { LazyLoadComponent } from "react-lazy-load-image-component"

import { Col, Container, Row } from "react-bootstrap"

import Hero from "../../components/hero/banner-quickbase.js"
import QuickbaseSections from "../../components/quick-base-solutions/QuickbaseSections"

import Banner from "../../components/hero/banner-hometeam.js"

import PartnerSwiper from "../../components/swiper/swiper-partner.js"

import UnlockEnterprise from "../../components/unlock-enterprise.js"
import Cases from "../../components/cases.js"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const QuickBaseSolutionPage = props => {
  const { data } = props

  return (
    <Layout banner={<Hero />} location={props.location}>
      <Seo
        title="Quickbase Elite Partner | Quandary Consulting Group"
        description="As an elite partner and solution provider for Quickbase, we rapidly build custom applications and integrations that get you more value out of the platform."
      />
      <LazyLoadComponent>
        <PartnerSwiper />
      </LazyLoadComponent>
      <LazyLoadComponent>
        <QuickbaseSections props={props} />
      </LazyLoadComponent>
      <CaseComponent dataSrc={data.allSanityCaseStudy} />
      <Banner />
      <UnlockEnterprise />
      <section
        className="bg-case-study slogan-section"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center">
              <div>
                <h2 className="text-white">
                  Take Your Quickbase Applications to The Next Level (Without
                  Putting More On Your Plate)
                </h2>
                <Link
                  to="/contact/"
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                  style={{ marginTop: "auto" }}
                >
                  Let's Chat
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default QuickBaseSolutionPage

export const quickbasePageQuery = graphql`
  query quickbasePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
