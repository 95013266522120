import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./hero.css"

const Banner = () => {
  //const { heroImage } = useStaticQuery(
  //  graphql`
  //    {
  //      heroImage: file(
  //        relativePath: { eq: "new-images/quickbase-banner-test.png" }
  //      ) {
  //        childImageSharp {
  //          gatsbyImageData(
  //            width: 1920
  //            layout: CONSTRAINED
  //            formats: [AUTO, WEBP, AVIF]
  //          )
  //        }
  //      }
  //    }
  //  `
  //)

  //const heroImageData = getImage(heroImage)

  // Use like this:
  //const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      {/*<BackgroundImage
        Tag="div"
        {...bgImage}
        // fluid={imageData}
      >*/}
      <div className="hero-banner relative pt-4 overflow-hidden">
        <div className="hero-overlay relative">
          <StaticImage
            placeholder="none"
            className="wave-img"
            src="./img/wave-white-right.png"
            alt="System Integration"
            layout="constrained"
            style={{ height: "fit-content", zIndex: 0 }}
            fetchpriority="low"
          />
        </div>
        <div className="container relative my-auto" style={{ zIndex: 1 }}>
          <div className="row pb-3 align-items-center">
            <div className="col-lg-7 col-xl-7 col-md-7">
              <div
                className="hero-custom-content-home review-content text-white"
                style={{ padding: "20px 0", marginTop: "5.3rem" }}
              >
                <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4 text-left">
                  Technology Partners
                </h5>
                <h1
                  className="page-title text-left"
                  style={{
                    marginBottom: "0.5rem",
                    display: "inline",
                  }}
                >
                  Get More Out of Your Quickbase Applications
                </h1>
                <p className="mb-0 mt-2 text-left">
                  As Quickbase Elite Partners, we go beyond point solutions to
                  create a robust, custom application ecosystem that increases
                  operational efficiency.
                </p>

                <div className="hero-button">
                  <Link
                    to="/contact"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center text-uppercase py-3"
                    style={{ marginTop: "auto" }}
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 col-md-5 pb-2 d-flex justify-content-end"
              style={{ position: "relative" }}
            >
              <StaticImage
                placeholder="none"
                className="rounded d-flex w-100"
                src="https://cdn.sanity.io/images/55lcecww/production/849c4db754941343d627ae15abe31e16573456b8-1800x1800.png"
                alt="Application Security Audit"
                layout="constrained"
                style={{ height: "fit-content", zIndex: 1 }}
                fetchpriority="low"
              />
            </div>
          </div>
        </div>
        <div className="swiperOverlay"></div>
      </div>
    </>
  )
}

export default Banner
