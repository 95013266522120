import React from "react"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import { Col, Container, Row } from "react-bootstrap"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { Link } from "gatsby"

import Testimonials2 from "../swiper/testimonial-swiper.js"

import Banner from "../hero/banner-hometeam.js"

import UnlockEnterprise from "../unlock-enterprise.js"
import Cases from "../cases.js"

import CaseStudySection from "../header/header-casestudy-section.js"

const ItConsulting = loadable(() => pMinDelay(import("../quickbase.js"), 500))

const BuildSection = loadable(() => pMinDelay(import("./BuildSection"), 500))
const CaseStudiesSection = loadable(() =>
  pMinDelay(import("./CaseStudiesSection"), 500)
)
const TransformSection = loadable(() =>
  pMinDelay(import("./TransformSection"), 500)
)
const StreamlineSection = loadable(() =>
  pMinDelay(import("./StreamlineSection"), 500)
)
const TrialSection = loadable(() => pMinDelay(import("./TrialSection"), 500))

const QuickbaseSections = props => {
  const { data } = props
  return (
    <>
      <div id="it-consult" className="it-consult-scroll">
        <ItConsulting />
      </div>
      <BuildSection />
      <Testimonials2 />
      <CaseStudySection />
    </>
  )
}

export default QuickbaseSections
